<template>
  <TableIconCellStyled class="cell">
    <TalpaIcon :scope="'AssetTypes'" :name="cell.icon" />
  </TableIconCellStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { TalpaIcon } from '@common/components'

const TableIconCellStyled = styled('div')`
  padding: 0.5rem;
  display: flex;
  @media (min-width: 1024px) {
    padding: 0.5rem 0.5rem 0.5rem 3rem;
  }
`

export default {
  props: {
    cell: {
      type: Object,
      required: true,
    },
  },
  components: {
    TableIconCellStyled,
    TalpaIcon,
  },
}
</script>
