var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MainWrapperStyled', [_vm.cell.label ? [_c('VPopover', {
    attrs: {
      "popperOptions": _vm.popperOptions,
      "placement": 'bottom-start',
      "autoHide": true,
      "trigger": 'click',
      "open": _vm.showPopup === _vm.cell.assetId,
      "popoverClass": 'userGuidePopOver',
      "container": '#app'
    },
    on: {
      "hide": _vm.hideOptions
    },
    scopedSlots: _vm._u([{
      key: "popover",
      fn: function fn() {
        return [_vm.cell.lastLog ? _c('PopoverStyled', [_vm._v(" " + _vm._s(_vm.$tc('lastUpdate', 1)) + ": " + _vm._s(_vm.cell.lastLog) + " "), _c('br'), _c('div', {
          staticClass: "confidencets"
        }, [_vm._v(" " + _vm._s(_vm.$t('shiftReportEvent.shiftGeneralInformation.lastCompleteData')) + ": "), _c('span', [_vm.loading ? _c('LoadingDots') : _c('span', [_vm._v(" " + _vm._s(_vm.confidenceTs) + " ")])], 1)])]) : _vm._e()];
      },
      proxy: true
    }], null, false, 2525341739)
  }, [_c('span', {
    staticClass: "popover-trigger",
    on: {
      "click": function click($event) {
        return _vm.toggleOptions(_vm.cell.assetId);
      }
    }
  }, [_c('span', {
    class: {
      disabled: _vm.cell.disabled
    }
  }, [_vm._v(_vm._s(_vm.cell.label) + " ")])])])] : _c('span', {
    staticClass: "message",
    attrs: {
      "title": _vm.cell.noData ? _vm.cell.noData : _vm.$t('messages.noDataAvailable')
    }
  }, [_vm._v("-")])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }