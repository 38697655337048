<template>
  <MainWrapperStyled>
    <template v-if="cell.label">
      <VPopover
        :popperOptions="popperOptions"
        :placement="'bottom-start'"
        :autoHide="true"
        :trigger="'click'"
        :open="showPopup === cell.assetId"
        @hide="hideOptions"
        :popoverClass="'userGuidePopOver'"
        :container="'#app'"
      >
        <span class="popover-trigger" @click="toggleOptions(cell.assetId)">
          <span
            :class="{
              disabled: cell.disabled,
            }"
            >{{ cell.label }}
          </span>
        </span>
        <template v-slot:popover>
          <PopoverStyled v-if="cell.lastLog">
            {{ $tc('lastUpdate', 1) }}: {{ cell.lastLog }} <br />
            <div class="confidencets">
              {{ $t('shiftReportEvent.shiftGeneralInformation.lastCompleteData') }}:
              <span>
                <LoadingDots v-if="loading" /> <span v-else> {{ confidenceTs }} </span>
              </span>
            </div>
          </PopoverStyled>
        </template>
      </VPopover>
    </template>
    <span class="message" v-else :title="cell.noData ? cell.noData : $t('messages.noDataAvailable')">-</span>
  </MainWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { VPopover } from 'v-tooltip'
import { DateTime } from 'luxon'
import chroma from 'chroma-js'
import { secondsTohhmmss } from '@/utils/filters/time'
import localesMixin from '@/mixins/locales'
import { formatDateAccordingToSettings } from '@/utils/time'
import { LoadingDots } from '@common/components'

import ASSET_CONFIDENCE_TS_QUERY from '#/graphql/operations/assets/assetConfidenceTimeStamp.gql'

const MainWrapperStyled = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 3rem;
  gap: 1rem;
  .popover-trigger {
    display: flex;
    cursor: pointer;
    > span {
      &.disabled {
        opacity: 0.5;
        color: ${p => p.theme.colors.navFontNormal};
      }
    }
  }
`
const PopoverStyled = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
  overflow: auto;
  max-height: 14rem;
  gap: 0.25rem;
  background: ${({ theme }) => theme.colors.solidBG};
  color: ${({ theme }) => theme.colors.normalFontColor};
  box-shadow: 0px 1px 4px ${({ theme }) => chroma(theme.colors.black).alpha(0.1).css()};
  padding: 0.5rem;
  border-radius: 0.5rem;
  .confidencets {
    display: flex;
    width: 22rem;
    > span {
      padding-left: 0.2rem;
    }
  }
`

export default {
  inject: ['uiSettings'],
  mixins: [localesMixin],

  props: {
    cell: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showPopup: '',
      assetConfidenceTimeStamp: '',
      loading: false,
    }
  },
  filters: {
    secondsTohhmmss,
  },
  components: {
    VPopover,
    MainWrapperStyled,
    LoadingDots,
    PopoverStyled,
  },
  computed: {
    popperOptions() {
      return {
        modifiers: {
          preventOverflow: {
            boundariesElement: 'scrollParent',
            escapeWithReference: true,
          },
        },
      }
    },
    confidenceTs() {
      return this.assetConfidenceTimeStamp
        ? formatDateAccordingToSettings(this.assetConfidenceTimeStamp, this.uiSettings, this.selectedTimezone)
        : this.$t('messages.dataOutOfRange')
    },
  },
  methods: {
    toggleOptions(id) {
      this.showPopup = ''
      this.showPopup = id
      if (this.showPopup === this.cell.assetId) {
        this.fetchConfidenceTs(id)
      }
    },
    hideOptions() {
      this.showPopup = ''
    },
    async fetchConfidenceTs(id) {
      try {
        this.loading = true
        const assetWithConfidenceTs = await this.$apollo.query({
          query: ASSET_CONFIDENCE_TS_QUERY,
          variables: {
            where: {
              assetIds: [id],
              toDate: DateTime.utc(),
            },
            fetchPolicy: 'cache-first',
          },
        })
        if (assetWithConfidenceTs.data.assetConfidenceTimeStamp[0].assetId) {
          this.loading = false
          this.assetConfidenceTimeStamp = assetWithConfidenceTs.data.assetConfidenceTimeStamp[0].confidenceTs
        }
      } catch (err) {
        this.loading = false
        this.assetConfidenceTimeStamp = ''
      }
    },
  },
}
</script>
