<template>
  <TableIconCellStyled class="cell">
    {{ value }}
  </TableIconCellStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import localesMixin from '@/mixins/locales'

const TableIconCellStyled = styled('div')`
  padding: 0.5rem;
  display: flex;
  @media (min-width: 1024px) {
    padding: 0.5rem 0.5rem 0.5rem 3rem;
  }
`
export default {
  mixins: [localesMixin],
  props: {
    cell: {
      type: Object,
      required: true,
    },
  },
  components: {
    TableIconCellStyled,
  },
  computed: {
    value() {
      if (this.cell?.headerId === 'header_operatingHours') {
        return this.cell?.value !== undefined && this.cell?.value !== null ? this.convUnit(this.cell?.value, 's', 'h', 2, false, true) : '-'
      }
      return this.cell?.value !== undefined && this.cell?.value !== null ? this.convUnit(this.cell?.value, 'h', 'h', 2, false, true) : '-'
    },
  },
}
</script>
