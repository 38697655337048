var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetMainStyled', [_c('TableV2', {
    staticClass: "asset-list",
    attrs: {
      "title": _vm.$tc('asset', 2),
      "headers": _vm.headers,
      "rows": _vm.rowsPaginated,
      "sorting": _vm.sorting,
      "filtersAvailable": _vm.filtersAvailable,
      "filterOptionsSelected": _vm.filterOptionsSelected,
      "isLoading": _vm.$apollo.queries.assets.loading,
      "searchQuery": _vm.searchQuery,
      "enableSearch": true,
      "enableFooter": false,
      "messages": _vm.messages,
      "fixedFilter": true,
      "filtersLoading": _vm.filtersLoading,
      "hasSavedFilters": _vm.hasSavedPreferences,
      "showLoadMore": _vm.showLoadMore,
      "totalRowCount": _vm.assets.length
    },
    on: {
      "update:sorting": function updateSorting($event) {
        _vm.sorting = $event;
      },
      "setSelectedFilterOption": _vm.setSelectedFilter,
      "resetFilters": _vm.resetFilters,
      "deleteFilters": _vm.deleteFilters,
      "saveFilters": _vm.saveFilters,
      "textFilter": _vm.textSearch,
      "copyToClipboard": _vm.copyToClipboard,
      "load-more-rows": _vm.loadMore
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }