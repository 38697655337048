var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TableIconCellStyled', {
    staticClass: "cell"
  }, [_c('TalpaIcon', {
    attrs: {
      "scope": 'AssetTypes',
      "name": _vm.cell.icon
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }