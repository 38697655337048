<template>
  <AssetMainStyled>
    <TableV2
      class="asset-list"
      :title="$tc('asset', 2)"
      :headers="headers"
      :rows="rowsPaginated"
      :sorting.sync="sorting"
      :filtersAvailable="filtersAvailable"
      :filterOptionsSelected="filterOptionsSelected"
      :isLoading="$apollo.queries.assets.loading"
      :searchQuery="searchQuery"
      :enableSearch="true"
      :enableFooter="false"
      :messages="messages"
      :fixedFilter="true"
      :filtersLoading="filtersLoading"
      :hasSavedFilters="hasSavedPreferences"
      :showLoadMore="showLoadMore"
      :totalRowCount="assets.length"
      @setSelectedFilterOption="setSelectedFilter"
      @resetFilters="resetFilters"
      @deleteFilters="deleteFilters"
      @saveFilters="saveFilters"
      @textFilter="textSearch"
      @copyToClipboard="copyToClipboard"
      @load-more-rows="loadMore"
    />
  </AssetMainStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { DateTime } from 'luxon'
import { orderBy, uniqBy, groupBy, get, partition, concat } from 'lodash'
import permissionsMixin from '@/mixins/permissions'
import localesMixin from '@/mixins/locales'

import { getUserIdFromToken } from '@common/utils'
import { formatDateAccordingToSettings } from '@/utils/time'

import TableV2 from '../Atomic/Molecules/TableV2.vue'
import TableRouterLinkCell from '../Atomic/Atoms/TableCells/TableRouterLinkCell.vue'
import TableIconCell from '../Atomic/Atoms/TableCells/TableIconCell.vue'
import AssetDimensionCell from '../Atomic/Atoms/TableCells/AssetDimensionCell.vue'
import AssetHealthStatusIcon from '@/components/Atomic/Molecules/TableAssetHealthStatusMolecule'
import { TABLE_SORT_TYPES } from '../../constants/settings'
import { LoadingDots } from '@common/components'
import { FlashMessages } from '@common/singletons'
import TableLastLogWithPopOver from '@/components/Atomic/Molecules/TableLastLogWithPopOver'
import TableLabelWithToolTipCell from '@/components/Atomic/Atoms/TableCells/TableLabelWithToolTipCell'

import { useAssetStore } from '@/stores/assets'

import ASSETS_MINIMAL_QUERY from '#/graphql/operations/assets/minimals/assetsMinimal.gql'
import MY_ASSETS_LAST_UPDATES_QUERY from '#/graphql/operations/assets/minimals/myAssetsLastUpdate.gql'
import MY_ASSETS_HEALTH_QUERY from '#/graphql/operations/assets/minimals/myAssetsHealth.gql'
import MY_ASSETS_TYRE_HEALTH_QUERY from '#/graphql/operations/assets/minimals/myAssetsTyreHealth.gql'
import MY_ASSETS_SITE_QUERY from '#/graphql/operations/assets/minimals/myAssetsSite.gql'
import OEMS_QUERY from '#/graphql/operations/assets/minimals/oems.gql'

import TABLE_SETTINGS_QUERY from '#/graphql/tableSettings/tableSettingses.gql'
import CREATE_TABLE_SETTINGS from '#/graphql/tableSettings/createTableSettings.gql'
import UPDATE_TABLE_SETTINGS from '#/graphql/tableSettings/updateTableSettings.gql'
import DELETE_TABLE_SETTINGS from '#/graphql/tableSettings/deleteTableSettings.gql'

const AssetMainStyled = styled('div')`
  padding: 0.5rem;
  height: calc(100% - 1rem);
  overflow: hidden;
  > .asset-list {
    max-height: calc(100%);
  }
  @media (min-width: 768px) {
    padding: 1rem;
    height: calc(100% - 2rem);
  }
`

export default {
  inject: ['uiSettings'],
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  mixins: [permissionsMixin, localesMixin],
  components: {
    AssetMainStyled,
    TableV2,
  },
  data() {
    return {
      filtersSelected: [],
      sorting: {
        key: 'lastLog',
        asc: true,
      },
      assets: [],
      assetsWithLastUpdates: [],
      assetsWithHealth: [],
      searchQuery: '',
      tableSettingses: [],
      tableSettings: null,
      assetWheelPositions: [],
      batchSize: 100, // Number of items to load initially
      loadedBatch: 0,
      totalCount: 0,
      assetsLastUpdateMap: new Map(),
      assetsHealthMap: new Map(),
      assetsSiteMap: new Map(),
      oemsMap: new Map(),
      assetsTyreHealthMap: new Map(),
      filtersMetaMap: new Map([
        [
          'filter_asset_type',
          {
            id: 'filter_asset_type',
            class: 'filter filter-asset-types',
            modelName: 'MachineType',
            placeholder: this.$tc('assetType', 2),
            searchable: false,
            isMultiple: true,
          },
        ],
        [
          'filter_manufactures',
          {
            id: 'filter_manufactures',
            class: 'filter filter-manufactures',
            modelName: 'OEM',
            placeholder: this.$tc('oem', 2),
            searchable: false,
            isMultiple: true,
          },
        ],
        [
          'filter_sites',
          {
            id: 'filter_sites',
            class: 'filter filter-sites',
            modelName: 'AssetSite',
            placeholder: this.$tc('site', 2),
            searchable: false,
            isMultiple: true,
          },
        ],
        [
          'filter_models',
          {
            id: 'filter_models',
            class: 'filter filter-models',
            modelName: 'MachineModel',
            placeholder: this.$tc('model', 2),
            searchable: false,
            isMultiple: true,
          },
        ],
        [
          'filter_location',
          {
            id: 'filter_location',
            class: 'filter filter-location',
            modelName: 'AssetPosition',
            placeholder: this.$tc('location', 2),
            searchable: false,
            isMultiple: true,
          },
        ],
        [
          'filter_health',
          {
            id: 'filter_health',
            class: 'filter filter-health',
            modelName: 'Health',
            placeholder: this.$t('health'),
            searchable: false,
            isMultiple: false,
          },
        ],
      ]),
      filterKeyToPathMap: new Map([
        [
          'MachineType',
          {
            assetPath: 'type.id',
            filterPath: 'id',
          },
        ],
        ['OEM', { assetPath: 'oem.id', filterPath: 'id' }],
        ['AssetSite', { assetPath: 'site.siteId', filterPath: 'id' }],
        ['MachineModel', { assetPath: 'model.id', filterPath: 'id' }],
        ['AssetPosition', { assetPath: 'lastPosition.geofence', filterPath: 'id' }],
        ['Health', { assetPath: 'health.id', filterPath: 'id' }],
      ]),
    }
  },
  computed: {
    locale() {
      return get(this.uiSettings, 'language', 'DE_DE').toLowerCase().replace('_', '-')
    },
    filterOptionsSelected() {
      const filterOptionsSelected = this.filtersSelected.map(filter => {
        const filterAvailable = this.filtersAvailable.find(f => f.modelName === filter.__typename)
        const filterValue = filterAvailable.options.find(o => o.id === filter.id)
        return filterValue
      })
      return filterOptionsSelected
    },
    selectedFilterKeys() {
      return Object.keys(groupBy(this.filtersSelected, '__typename'))
    },
    uniqueAssetTypes() {
      const uniqTypes = uniqBy(this.filterOptionsData('MachineType'), 'type.name').map(asset => {
        return {
          ...asset.type,
          label: this.$t(`assetTypes.${asset.type.name?.toLowerCase().split(' ').join('')}`),
        }
      })
      return orderBy(uniqTypes, 'label', 'asc')
    },
    uniqueAssetTypeOptions() {
      return this.uniqueAssetTypes.map(m => ({
        ...m,
        id: m.id + '',
        label: this.$t(`assetTypes.${m.name.toLowerCase()?.split(' ').join('')}`),
        isSingleSelect: false,
      }))
    },
    uniqueManufacturers() {
      return uniqBy(this.filterOptionsData('OEM'), 'oem.id')
        .map(asset => asset.oem)
        .filter(f => f && f.id)
    },
    uniqueManufacturerOptions() {
      return this.uniqueManufacturers.map(m => ({
        ...m,
        id: m.id + '',
        label: m.alias,
        isSingleSelect: false,
        sortingLabel: m.alias.toLowerCase(),
      }))
    },
    uniqueSites() {
      return uniqBy(this.filterOptionsData('AssetSite'), 'site.siteId')
        .map(asset => asset?.site)
        .filter(site => site)
    },
    uniqueSiteOptions() {
      return this.uniqueSites.map(m => ({
        ...m,
        id: m.siteId + '',
        label: m?.alias,
        isSingleSelect: false,
        sortingLabel: m?.alias.toLowerCase(),
      }))
    },
    uniqueHealth() {
      return uniqBy(this.filterOptionsData('Health'), 'health.id')
        .map(asset => asset.health)
        .filter(health => health.id !== 0)
    },
    uniqueHealthOptions() {
      return this.uniqueHealth.map(m => ({
        ...m,
        id: m.id + '',
        label: get(
          this.healthStatusMapper.find(({ id }) => id === m?.id),
          'value',
        ),
        isSingleSelect: true,
      }))
    },
    uniqueModels() {
      return uniqBy(this.filterOptionsData('MachineModel'), 'model.id')
        .map(asset => asset.model)
        .filter(model => model)
    },
    uniqueModelOptions() {
      return this.uniqueModels.map(m => ({
        ...m,
        id: m.id + '',
        label: m?.name,
        isSingleSelect: false,
        sortingLabel: m?.name.toLowerCase(),
      }))
    },
    uniqueLocations() {
      return uniqBy(this.filterOptionsData('AssetPosition'), 'lastPosition.geofence')
        .map(asset => asset.lastPosition)
        .filter(location => location && location.geofence !== null)
    },
    uniqueLocationOptions() {
      return this.uniqueLocations.map(m => ({
        ...m,
        id: m.geofence + '',
        label: m.geofence,
        isSingleSelect: false,
        sortingLabel: m.geofence.toLowerCase(),
      }))
    },
    healthStatusMapper() {
      return [
        { id: 3, value: this.$tc('assetHealthStatus.Red') },
        { id: 2, value: this.$tc('assetHealthStatus.Yellow') },
        { id: 1, value: this.$tc('assetHealthStatus.Green') },
      ]
    },
    filtersAvailable() {
      const filters = [
        {
          ...this.filtersMetaMap.get('filter_asset_type'),
          options: this.uniqueAssetTypeOptions,
          value: this.filtersSelected
            .filter(f => f.__typename === 'MachineType')
            .map(f => {
              const val = this.uniqueAssetTypeOptions?.find(o => o.id === f.id)
              return {
                ...val,
                label: this.$t(`assetTypes.${val?.name.toLowerCase()?.split(' ').join('')}`),
              }
            }),
        },
        {
          ...this.filtersMetaMap.get('filter_manufactures'),
          options: orderBy(this.uniqueManufacturerOptions, 'sortingLabel', 'asc'),
          value: this.filtersSelected
            .filter(f => f.__typename === 'OEM')
            .map(f => this.uniqueManufacturerOptions?.find(o => o.id === f.id)),
        },
        {
          ...this.filtersMetaMap.get('filter_sites'),
          options: orderBy(this.uniqueSiteOptions, 'sortingLabel', 'asc'),
          value: this.filtersSelected.filter(f => f.__typename === 'AssetSite').map(f => this.uniqueSiteOptions?.find(o => o.id === f.id)),
        },
        {
          ...this.filtersMetaMap.get('filter_models'),
          options: orderBy(this.uniqueModelOptions, 'sortingLabel', 'asc'),
          value: this.filtersSelected
            .filter(f => f.__typename === 'MachineModel')
            .map(f => this.uniqueModelOptions?.find(o => o.id === f.id)),
        },
        {
          ...this.filtersMetaMap.get('filter_location'),
          options: orderBy(this.uniqueLocationOptions, 'sortingLabel', 'asc'),
          value: this.filtersSelected
            .filter(f => f.__typename === 'AssetPosition')
            .map(f => this.uniqueLocationOptions?.find(o => o.id === f.id)),
        },
      ]
      if (this.hasMachineStatusReadPermission) {
        filters.push({
          ...this.filtersMetaMap.get('filter_health'),
          options: this.uniqueHealthOptions,
          value: this.translatedHealthLabels,
        })
      }
      return filters
    },
    translatedHealthLabels() {
      const selectedOption = this.filtersSelected.filter(f => f.__typename === 'Health')
      return selectedOption.map(option => {
        option.label = get(
          this.healthStatusMapper.find(({ id }) => id + '' === option?.id),
          'value',
        )
        return {
          ...option,
        }
      })
    },
    headers() {
      const headers = [
        {
          id: `header_type`,
          label: this.$tc('type', 1),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'type.name',
          size: 'xsmall',
        },
        {
          id: `header_name`,
          label: this.$tc('asset', 1),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'name',
          size: 'medium',
        },
        {
          id: `header_last_update`,
          label: this.$tc('lastUpdate', 1),
          sortType: TABLE_SORT_TYPES.NEW_TO_OLD,
          isSortable: true,
          sortKey: 'lastLog',
          size: 'medium',
        },
        {
          id: `header_totalMachineHours`,
          label: this.$tc('totalMachineHours', 1),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'lastPosition.totalMachineHours',
          size: 'medium',
          unit: 'h',
        },
        {
          id: `header_geofence`,
          label: this.$tc('geofence', 1),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'lastPosition.geofence',
          size: 'medium',
        },
        {
          id: `header_site`,
          label: this.$tc('site', 1),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'site.name',
          size: 'medium',
        },
        {
          id: `header_oem`,
          label: this.$tc('oem', 1),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'oem.alias',
          size: 'small',
        },
        {
          id: `header_model`,
          label: this.$tc('model', 1),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'model.name',
          size: 'small',
        },
      ]
      if (this.hasMachineStatusReadPermission) {
        headers.splice(2, 0, {
          id: `header_health`,
          label: this.$t('health'),
          sortType: TABLE_SORT_TYPES.OK_TO_ERROR,
          isSortable: true,
          sortKey: 'health.id',
          size: 'medium',
        })
      }
      return headers
    },
    mappedAssets() {
      const colorsOrderMap = {
        Red: 3,
        Yellow: 2,
        Green: 1,
      }
      if (this.hasMachineStatusReadPermission) {
        return this.assets.map(asset => {
          const lastUpdateData = this.assetsLastUpdateMap.get(asset.id)
          const oem = this.oemsMap.get(asset.manufacturerCuid)
          const site = this.assetsSiteMap.get(asset.id)
          const healthData = this.assetsHealthMap.get(asset.id)
          const tyreHealth = this.assetsTyreHealthMap.get(asset.id)
          const finalHealth = this.healthMapper([healthData?.status, tyreHealth])
          return {
            ...asset,
            lastLog: lastUpdateData?.time ?? null,
            lastPosition: lastUpdateData,
            oem: oem,
            site: site,
            health: {
              id: colorsOrderMap[finalHealth] || 0,
              status: finalHealth,
              __typename: 'Health',
            },
            activeNotifications: (healthData?.activeNotifications || []).filter(notification => notification),
          }
        })
      } else {
        return this.assets.map(asset => {
          const lastUpdateData = this.assetsLastUpdateMap.get(asset.id)
          const oem = this.oemsMap.get(asset.manufacturerCuid)
          const site = this.assetsSiteMap.get(asset.id)
          return {
            ...asset,
            lastLog: lastUpdateData?.time ?? null,
            lastPosition: lastUpdateData,
            oem: oem,
            site: site,
          }
        })
      }
    },

    assetsFiltered() {
      if (this.filtersSelected.length > 0) {
        return this.mappedAssets.reduce((assetsFiltered, asset) => {
          const filterGroups = groupBy(this.filtersSelected, '__typename')
          const notInAllGroups = Object.keys(filterGroups).some(key => {
            const filterGroup = filterGroups[key]
            let { assetPath, filterPath } = this.filterKeyToPathMap.get(key)
            const found = filterGroup.find(filter => {
              return get(filter, filterPath) === get(asset, assetPath) + ''
            })
            return !found
          })
          if (!notInAllGroups) {
            assetsFiltered.push(asset)
          }
          return assetsFiltered
        }, [])
      }
      return this.mappedAssets
    },
    assetsSorted() {
      const sortingKey = this.sorting.key
      const partitions = partition(this.assetsFiltered, x => !!get(x, sortingKey, null))
      const sortDirection = sortingKey === 'lastLog' ? (this.sorting.asc ? 'desc' : 'asc') : this.sorting.asc ? 'asc' : 'desc'
      const sortedPartitions = partitions.map(partition => orderBy(partition, [sortingKey], [sortDirection]))
      const result = concat(...sortedPartitions)
      this.resetBatch()
      return result
    },
    rows() {
      const locale = this.locale
      const rows = this.assetsSorted.map(asset => {
        let cells = [
          {
            id: `${asset.id}_type`,
            sortableValue: asset?.type?.name,
            component: this.$apollo.queries.assets.loading ? LoadingDots : TableIconCell,
            icon: asset.type?.name?.split(' ').join(''),
            headerId: 'header_type',
          },
          {
            id: `${asset.id}_name`,
            sortableValue: asset?.name,
            component: this.$apollo.queries.assets.loading ? LoadingDots : TableRouterLinkCell,
            linkName: 'AssetOverview',
            linkLabel: asset?.name,
            linkParams: { id: asset.id },
            isMobilePrimarycol: true,
            mobilePrimarycolLabel: asset?.name,
            headerId: `header_name`,
          },
          {
            id: `${asset.id}_last_update`,
            sortableValue: asset?.lastLog ? DateTime.fromISO(asset.lastLog).setLocale(locale).toRelative() : '',
            label: asset?.lastLog ? DateTime.fromISO(asset.lastLog).setLocale(locale).toRelative() : '',
            lastLog: `${formatDateAccordingToSettings(asset?.lastLog, this.uiSettings, this.selectedTimezone)}`,
            disabled: asset?.lastLog ? !this.isLastLogWithinRange(asset?.lastLog, asset.name) : false,
            component: this.$apollo.queries.myAssetsLastUpdate.loading ? LoadingDots : TableLastLogWithPopOver,
            assetId: asset.id,
            headerId: `header_last_update`,
          },
          {
            id: `${asset.id}_totalMachineHours`,
            sortableValue: asset?.lastPosition?.totalMachineHours,
            value: asset?.lastPosition?.totalMachineHours === 0 ? null : asset?.lastPosition?.totalMachineHours,
            component: AssetDimensionCell,
            headerId: `header_totalMachineHours`,
          },
          {
            id: `${asset.id}_geofence`,
            sortableValue: asset?.lastPosition?.geofence,
            label: asset?.lastPosition?.geofence,
            tooltipLabel: `${this.$tc('lastUpdate', 1)} : ${formatDateAccordingToSettings(
              asset?.lastLog,
              this.uiSettings,
              this.selectedTimezone,
            )}`,
            component: this.$apollo.queries.myAssetsLastUpdate.loading ? LoadingDots : TableLabelWithToolTipCell,
            headerId: `header_geofence`,
          },
          {
            id: `${asset.id}_site`,
            sortableValue: asset?.site?.name,
            headerId: `header_site`,
          },
          {
            id: `${asset.id}_oem`,
            sortableValue: asset?.oem?.alias,
            headerId: `header_oem`,
          },
          {
            id: `${asset.id}_model`,
            sortableValue: asset?.model.name,
            headerId: `header_model`,
          },
        ]
        if (this.hasMachineStatusReadPermission) {
          cells.splice(2, 0, {
            id: `${asset.id}_health`,
            headerId: `header_health`,
            label: this.$t('health'),
            isSortable: true,
            sortableValue: asset?.health?.id,
            status: asset?.health?.status,
            assetId: asset.id,
            tooltipContent: asset.activeNotifications ? asset.activeNotifications : [],
            component: this.$apollo.queries.myAssetsHealth.loading ? LoadingDots : AssetHealthStatusIcon,
          })
        }
        return {
          rowId: `row_${asset.id}`,
          cells,
        }
      })

      let filteredTable = rows
      if (this.searchQuery !== '') {
        filteredTable = rows.filter(row => {
          const searchQueryLower = this.searchQuery.toLowerCase()
          return row.cells.some(item => {
            const sortableValue = item.sortableValue
            if (sortableValue !== undefined && sortableValue !== null) {
              const prop = typeof sortableValue === 'string' ? sortableValue.toLowerCase() : sortableValue.toString(10)
              return prop.includes(searchQueryLower)
            }
            return false
          })
        })
      }
      return filteredTable
    },
    rowsPaginated() {
      this.setCounter(this.rows.length)
      return this.rows.slice(0, this.batchSize * (this.loadedBatch + 1))
    },
    showLoadMore() {
      return this.rowsPaginated.length < this.totalCount
    },
    hasMachineStatusReadPermission() {
      return Boolean(this.machineStatusRead)
    },
    hasTyreReadPermission() {
      return Boolean(this.tyreReadPermission)
    },
    messages() {
      let message = ''
      if (this.rowsPaginated.length === 0) {
        message = this.searchQuery === '' ? this.$tc('messages.noData') : this.$t('messages.searchNoResults', { query: this.searchQuery })
      }
      return message
    },
    userId() {
      return getUserIdFromToken(this.$keycloak.token)
    },
    hasSavedPreferences() {
      return this.tableSettings && (this.tableSettings?.filters.length > 0 || this.tableSettings.sortings?.length > 0)
    },
    filtersLoading() {
      return (
        this.$apollo.queries.assets.loading ||
        this.$apollo.queries.myAssetsHealth.loading ||
        this.$apollo.queries.myAssetsLastUpdate.loading ||
        this.$apollo.queries.tableSettingses.loading
      )
    },
  },
  methods: {
    setCounter(count) {
      this.totalCount = count
    },
    resetBatch() {
      this.loadedBatch = 0
    },
    filterOptionsData(typeName) {
      if (this.selectedFilterKeys && this.filtersSelected[0]?.__typename !== typeName) {
        const localSelectedKeys = [...this.selectedFilterKeys]
        const indexOfType = localSelectedKeys?.indexOf(typeName)
        if (indexOfType > -1) {
          localSelectedKeys.splice(indexOfType, localSelectedKeys.length)
        }
        const mappedfiltersSelected = this.filtersSelected.filter(f => localSelectedKeys.includes(f.__typename))

        if (mappedfiltersSelected.length > 0) {
          return this.mappedAssets.reduce((assetsFiltered, asset) => {
            const filterGroups = groupBy(mappedfiltersSelected, '__typename')
            const notInAllGroups = Object.keys(filterGroups).some(key => {
              const filterGroup = filterGroups[key]
              let { assetPath, filterPath } = this.filterKeyToPathMap.get(key)
              const found = filterGroup.find(filter => get(filter, filterPath) === get(asset, assetPath) + '')
              return !found
            })
            if (!notInAllGroups) {
              assetsFiltered.push(asset)
            }
            return assetsFiltered
          }, [])
        }
      }
      return this.mappedAssets
    },
    textSearch(query) {
      this.searchQuery = query
      this.resetBatch()
    },
    setSelectedFilter(filter) {
      const found = this.filtersSelected.find(f => f.id === filter.id)
      if (found) {
        this.filtersSelected = this.filtersSelected.filter(f => f.id !== filter.id)
      } else {
        if (filter.isSingleSelect) {
          this.filtersSelected = this.filtersSelected.filter(f => f.__typename !== filter.__typename)
        }
        this.filtersSelected.push({
          isSingleSelect: filter.isSingleSelect,
          id: filter.id + '',
          __typename: filter.__typename,
        })
      }
    },
    resetFilters() {
      this.filtersSelected = []
      this.sorting = {
        key: 'lastLog',
        asc: true,
      }
    },
    healthMapper(healthData) {
      const combinedHealth = healthData.includes('Red')
        ? 'Red'
        : healthData.includes('Yellow')
        ? 'Yellow'
        : healthData.includes('Green')
        ? 'Green'
        : null
      return combinedHealth
    },
    saveFilters() {
      if (this.tableSettings) {
        this.updateTableSettings()
      } else {
        this.createTableSettings()
      }
    },
    async copyToClipboard() {
      let assetIds = this.rows.map(row => {
        let cell = row.cells[2]
        return cell['assetId']
      })
      await navigator.clipboard
        .writeText(assetIds.join(', '))
        .then(() => {
          this.$emit('copyToClipboard', assetIds.length)
          FlashMessages.$emit('success', this.$t('table.copy.successMessage', { count: assetIds.length }), {
            timeout: 1500,
          })
        })
        .catch(() => {
          FlashMessages.$emit('error', this.$t('table.copy.errorMessage'), {
            timeout: 1500,
          })
        })
    },
    async createTableSettings() {
      const data = {
        ownerUserId: this.userId,
        type: 'ASSETSLIST',
      }
      if (this.filtersSelected.length > 0) {
        const filters = this.filtersSelected.map(f => {
          return {
            field: f.__typename,
            value: f.id,
          }
        })
        data.filters = {
          create: filters,
        }
      }
      data.sortings = {
        create: [
          {
            field: this.sorting.key,
            order: this.sorting.asc === true ? 'ASC' : 'DESC',
          },
        ],
      }

      const res = await this.$apollo.mutate({
        mutation: CREATE_TABLE_SETTINGS,
        variables: {
          data,
        },
        update: (store, { data: { createTableSettings } }) => {
          const data = store.readQuery({
            query: TABLE_SETTINGS_QUERY,
            variables: {
              where: {
                type: 'ASSETSLIST',
                ownerUserId: this.userId,
              },
            },
          })
          data.tableSettingses[0] = createTableSettings
          this.tableSettings = createTableSettings
          store.writeQuery({ query: TABLE_SETTINGS_QUERY, data })
        },
      })
      if (res?.data?.createTableSettings?.id) {
        FlashMessages.$emit('success', this.$t('table.filter.successMessage'), {
          timeout: 5000,
        })
      }
    },
    async updateTableSettings() {
      const data = {
        ownerUserId: this.userId,
        type: 'ASSETSLIST',
      }

      if (this.tableSettings.sortings.length > 0) {
        const sortingId = this.tableSettings?.sortings[0].id

        data.sortings = {
          update: [
            {
              where: {
                id: sortingId,
              },
              data: {
                field: this.sorting.key,
                order: this.sorting.asc === true ? 'ASC' : 'DESC',
              },
            },
          ],
        }
      } else {
        data.sortings = {
          create: [
            {
              field: this.sorting.key,
              order: this.sorting.asc === true ? 'ASC' : 'DESC',
            },
          ],
        }
      }
      const filters = {
        delete: [],
        create: [],
      }
      this.tableSettings?.filters?.forEach(filter => {
        const found = this.filtersSelected?.find(f => f.id === filter.value && f.__typename === filter.field)
        if (!found) {
          filters.delete.push({ id: filter.id })
        }
      })
      this.filtersSelected?.forEach(filter => {
        const found = this.tableSettings?.filters?.find(f => f.value === filter.id && f.field === filter.__typename)
        if (!found) {
          filters.create.push({
            field: filter.__typename,
            value: filter.id,
          })
        }
      })
      data.filters = filters
      const res = await this.$apollo.mutate({
        mutation: UPDATE_TABLE_SETTINGS,
        variables: {
          where: {
            id: this.tableSettings.id,
          },
          data,
        },
        update: (store, { data: { updateTableSettings } }) => {
          const data = store.readQuery({
            query: TABLE_SETTINGS_QUERY,
            variables: {
              where: {
                type: 'ASSETSLIST',
                ownerUserId: this.userId,
              },
            },
          })
          data.tableSettingses[0] = updateTableSettings
          this.tableSettings = updateTableSettings
          store.writeQuery({ query: TABLE_SETTINGS_QUERY, data })
        },
      })
      if (res?.data?.updateTableSettings?.id) {
        FlashMessages.$emit('success', this.$t('table.filter.successMessage'), {
          timeout: 5000,
        })
      }
    },
    async deleteFilters() {
      if (!this.tableSettings?.id) {
        return
      }
      const resp = await this.$apollo.mutate({
        mutation: DELETE_TABLE_SETTINGS,
        variables: {
          where: {
            id: this.tableSettings.id,
          },
        },
        update: store => {
          const data = store.readQuery({
            query: TABLE_SETTINGS_QUERY,
            variables: {
              where: {
                type: 'ASSETSLIST',
                ownerUserId: this.userId,
              },
            },
          })
          data.tableSettingses[0] = null
          store.writeQuery({ query: TABLE_SETTINGS_QUERY, data })
        },
      })
      if (resp?.data?.deleteTableSettings?.id) {
        this.filtersSelected = []
        this.tableSettings = null
        this.sorting = {
          key: 'lastLog',
          asc: true,
        }
        FlashMessages.$emit('success', this.$t('table.filter.deleteSuccessMessage'), {
          timeout: 5000,
        })
      }
    },
    isLastLogWithinRange(ts) {
      return DateTime.fromISO(ts).toMillis() > DateTime.utc().minus({ hours: 24 }).toMillis()
    },
    loadMore() {
      this.loadedBatch++
    },
  },
  apollo: {
    assets: {
      query: ASSETS_MINIMAL_QUERY,
      variables: {
        where: {
          isVisible: {
            in: [true],
          },
        },
      },
      result({ data }) {
        this.assetStore.allAssets = data?.assets ?? []
      },
    },
    myAssetsLastUpdate: {
      query: MY_ASSETS_LAST_UPDATES_QUERY,
      result({ data }) {
        this.assetsLastUpdateMap = new Map(data.myAssetsLastUpdate.map(asset => [asset.assetId, asset]))
      },
    },
    myAssetsHealth: {
      query: MY_ASSETS_HEALTH_QUERY,
      skip() {
        return !this.hasMachineStatusReadPermission
      },
      result({ data }) {
        this.assetsHealthMap = new Map(data.myAssetsHealth.map(asset => [asset.assetId, asset]))
      },
    },
    myAssetsTyreHealth: {
      query: MY_ASSETS_TYRE_HEALTH_QUERY,
      skip() {
        return !this.hasTyreReadPermission
      },
      result({ data }) {
        this.assetsTyreHealthMap = new Map(data.myAssetsTyreHealth.map(asset => [asset.assetId, asset]))
      },
    },
    myAssetsSite: {
      query: MY_ASSETS_SITE_QUERY,
      result({ data }) {
        this.assetsSiteMap = new Map(data.myAssetsSite.map(asset => [asset.assetId, asset]))
      },
    },
    oems: {
      query: OEMS_QUERY,
      result({ data }) {
        this.oemsMap = new Map(data.oems.map(oem => [oem.id, oem]))
      },
    },
    tableSettingses: {
      query: TABLE_SETTINGS_QUERY,
      variables() {
        return {
          where: {
            type: 'ASSETSLIST',
            ownerUserId: this.userId,
          },
        }
      },
      skip() {
        return !this.userId
      },
      result({ data }) {
        if (data.tableSettingses && data.tableSettingses.length < 1) {
          return []
        }
        this.tableSettings = data.tableSettingses[0]
        if (this.tableSettings?.filters?.length > 0 && this.filtersSelected.length < 1) {
          this.tableSettings.filters.forEach(filter => {
            this.filtersSelected.push({
              id: filter.value,
              __typename: filter.field,
            })
          })
        }
        if (this.tableSettings?.sortings?.length > 0) {
          this.sorting.key = this.tableSettings.sortings[0].field
          this.sorting.asc = this.tableSettings.sortings[0].order === 'ASC' ? true : false
        }
      },
    },
  },
}
</script>
